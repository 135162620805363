/* eslint-disable jsx-a11y/anchor-is-valid */
import i18n from "i18next";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import HamburgerIcon from "../../assets/img/icon/hamburger.png";
import "./Navbar.css";

const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [toggleNav, setToogleNav] = useState(true);
  const { isLoggedIn, logout } = useContext(AuthContext);
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const logOut = () => {
    Cookies.remove("access_token");
    logout();
    return <Link to={`/admin/login`} replace />;
  };

  useEffect(() => {
    let isMounted = true; // Flag to check if component is mounted

    const handleResize = () => {
      if (isMounted) {
        setWindowWidth(window.innerWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    if (window.innerWidth <= 507) {
      setToogleNav(false);
    } else {
      setToogleNav(true);
    }

    return () => {
      isMounted = false;
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth <= 507 ? (
        <div id="WindowWidth<=507">
          <nav
            className="my-nav"
            role="navigation"
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div>
              <button
                style={{
                  cursor: "pointer",
                  fontSize: "60px",
                  marginLeft: "6vw",
                  height: "10px",
                }}
                onClick={() => {
                  setToogleNav(!toggleNav);
                }}
              >
                <img
                  style={{ paddingTop: "15px" }}
                  height={10}
                  width={25}
                  alt="hamburger"
                  src={HamburgerIcon}
                />
              </button>
            </div>
          </nav>
          {toggleNav && (
            <ul
              style={{
                position: "fixed",
                backgroundColor: "white",
                zIndex: 1000,
              }}
              className="navHeader"
            >
              <li>
                <Link className="links" to="/">
                  {t("nav1")}
                </Link>
              </li>
              <li>
                <Link className="links" to="/apartments">
                  {t("nav2")}
                </Link>
              </li>

              <li>
                <Link to="/about" className="links">
                  {t("nav3")}
                </Link>
              </li>
              <li>
                <Link to="/podgora" className="links">
                  {t("nav4")}
                </Link>
              </li>
              <li>
                <a className="links">{t("language")}</a>
                <ul>
                  <li>
                    <a onClick={() => changeLanguage("en")}>English</a>
                  </li>
                  <li>
                    <a onClick={() => changeLanguage("hr")}>Hrvatski</a>
                  </li>
                </ul>
              </li>
              {isLoggedIn ? (
                <li>
                  <a
                    onClick={() => logOut()}
                    style={{ color: "red" }}
                    className="links"
                  >
                    LOGOUT
                  </a>
                </li>
              ) : (
                <></>
              )}
            </ul>
          )}
        </div>
      ) : (
        <div id="WindowWidth>507">
          <nav
            className="my-nav"
            role="navigation"
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingTop: "10px",
              padding: "10px",
              height: "100%",
            }}
          >
            <div className="logoAnd"></div>
            {toggleNav && (
              <ul className="navHeader">
                <li>
                  <Link className="links" to="/">
                    {t("nav1")}
                  </Link>
                </li>
                <li>
                  <Link className="links" to="/apartments">
                    {t("nav2")}
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="links">
                    {t("nav3")}
                  </Link>
                </li>
                <li>
                  <Link to="/podgora" className="links">
                    {t("nav4")}
                  </Link>
                </li>
                <li>
                  <a style={{ paddingLeft: "30px" }} className="links">
                    {t("language")}
                  </a>
                  <ul>
                    <li>
                      <a onClick={() => changeLanguage("en")}>English</a>
                    </li>
                    <li>
                      <a onClick={() => changeLanguage("hr")}>Hrvatski</a>
                    </li>
                  </ul>
                </li>
                {isLoggedIn ? (
                  <li>
                    <a
                      onClick={() => logOut()}
                      style={{ color: "red" }}
                      className="links"
                    >
                      LOGOUT
                    </a>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            )}
          </nav>
        </div>
      )}
    </>
  );
};

export default withTranslation()(Navbar);
