import ap1_9Thumb from "../assets/img/ap1webp-compressed/100-min-thumb.webp";
import ap1_9 from "../assets/img/ap1webp-compressed/100-min.webp";
import ap1_19Thumb from "../assets/img/ap1webp-compressed/101-min-thumb.webp";
import ap1_19 from "../assets/img/ap1webp-compressed/101-min.webp";
import ap1_20Thumb from "../assets/img/ap1webp-compressed/102-min-thumb.webp";
import ap1_20 from "../assets/img/ap1webp-compressed/102-min.webp";
import ap1_21Thumb from "../assets/img/ap1webp-compressed/103-min-thumb.webp";
import ap1_21 from "../assets/img/ap1webp-compressed/103-min.webp";
import ap1_22Thumb from "../assets/img/ap1webp-compressed/105-min-thumb.webp";
import ap1_22 from "../assets/img/ap1webp-compressed/105-min.webp";
import ap1_23Thumb from "../assets/img/ap1webp-compressed/108-min-thumb.webp";
import ap1_23 from "../assets/img/ap1webp-compressed/108-min.webp";
import ap1_24Thumb from "../assets/img/ap1webp-compressed/120-min-thumb.webp";
import ap1_24 from "../assets/img/ap1webp-compressed/120-min.webp";

import ap1_1Thumb from "../assets/img/ap1webp-compressed/66-min-thumb.webp";
import ap1_1 from "../assets/img/ap1webp-compressed/66-min.webp";
import ap1_10Thumb from "../assets/img/ap1webp-compressed/68-min-thumb.webp";
import ap1_10 from "../assets/img/ap1webp-compressed/68-min.webp";
import ap1_11Thumb from "../assets/img/ap1webp-compressed/69-min-thumb.webp";
import ap1_11 from "../assets/img/ap1webp-compressed/69-min.webp";
import ap1_12Thumb from "../assets/img/ap1webp-compressed/70-min-thumb.webp";
import ap1_12 from "../assets/img/ap1webp-compressed/70-min.webp";
import ap1_13Thumb from "../assets/img/ap1webp-compressed/71-min-thumb.webp";
import ap1_13 from "../assets/img/ap1webp-compressed/71-min.webp";
import ap1_14Thumb from "../assets/img/ap1webp-compressed/78-min-thumb.webp";
import ap1_14 from "../assets/img/ap1webp-compressed/78-min.webp";
import ap1_15Thumb from "../assets/img/ap1webp-compressed/80-min-thumb.webp";
import ap1_15 from "../assets/img/ap1webp-compressed/80-min.webp";
import ap1_16Thumb from "../assets/img/ap1webp-compressed/81-min-thumb.webp";
import ap1_16 from "../assets/img/ap1webp-compressed/81-min.webp";
import ap1_17Thumb from "../assets/img/ap1webp-compressed/84-min-thumb.webp";
import ap1_17 from "../assets/img/ap1webp-compressed/84-min.webp";
import ap1_18Thumb from "../assets/img/ap1webp-compressed/86-min-thumb.webp";
import ap1_18 from "../assets/img/ap1webp-compressed/86-min.webp";
import ap1_2Thumb from "../assets/img/ap1webp-compressed/87-min-thumb.webp";
import ap1_2 from "../assets/img/ap1webp-compressed/87-min.webp";
import ap1_3Thumb from "../assets/img/ap1webp-compressed/88-min-thumb.webp";
import ap1_3 from "../assets/img/ap1webp-compressed/88-min.webp";
import ap1_4Thumb from "../assets/img/ap1webp-compressed/89-min-thumb.webp";
import ap1_4 from "../assets/img/ap1webp-compressed/89-min.webp";
import ap1_5Thumb from "../assets/img/ap1webp-compressed/92-min-thumb.webp";
import ap1_5 from "../assets/img/ap1webp-compressed/92-min.webp";
import ap1_6Thumb from "../assets/img/ap1webp-compressed/95-min-thumb.webp";
import ap1_6 from "../assets/img/ap1webp-compressed/95-min.webp";
import ap1_7Thumb from "../assets/img/ap1webp-compressed/97-min-thumb.webp";
import ap1_7 from "../assets/img/ap1webp-compressed/97-min.webp";
import ap1_8Thumb from "../assets/img/ap1webp-compressed/98-min-thumb.webp";
import ap1_8 from "../assets/img/ap1webp-compressed/98-min.webp";

const apOneImages = [
  {
    original: ap1_1,
    thumbnail: ap1_1Thumb,
  },
  {
    original: ap1_2,
    thumbnail: ap1_2Thumb,
  },
  {
    original: ap1_3,
    thumbnail: ap1_3Thumb,
  },
  {
    original: ap1_4,
    thumbnail: ap1_4Thumb,
  },
  {
    original: ap1_5,
    thumbnail: ap1_5Thumb,
  },
  {
    original: ap1_6,
    thumbnail: ap1_6Thumb,
  },
  {
    original: ap1_7,
    thumbnail: ap1_7Thumb,
  },
  {
    original: ap1_8,
    thumbnail: ap1_8Thumb,
  },
  {
    original: ap1_9,
    thumbnail: ap1_9Thumb,
  },
  {
    original: ap1_10,
    thumbnail: ap1_10Thumb,
  },
  {
    original: ap1_11,
    thumbnail: ap1_11Thumb,
  },
  {
    original: ap1_12,
    thumbnail: ap1_12Thumb,
  },
  {
    original: ap1_13,
    thumbnail: ap1_13Thumb,
  },
  {
    original: ap1_14,
    thumbnail: ap1_14Thumb,
  },
  {
    original: ap1_15,
    thumbnail: ap1_15Thumb,
  },
  {
    original: ap1_16,
    thumbnail: ap1_16Thumb,
  },
  {
    original: ap1_17,
    thumbnail: ap1_17Thumb,
  },
  {
    original: ap1_18,
    thumbnail: ap1_18Thumb,
  },
  {
    original: ap1_19,
    thumbnail: ap1_19Thumb,
  },
  {
    original: ap1_20,
    thumbnail: ap1_20Thumb,
  },
  {
    original: ap1_21,
    thumbnail: ap1_21Thumb,
  },
  {
    original: ap1_22,
    thumbnail: ap1_22Thumb,
  },
  {
    original: ap1_23,
    thumbnail: ap1_23Thumb,
  },
  {
    original: ap1_24,
    thumbnail: ap1_24Thumb,
  },
];

export default apOneImages;
