import React from "react";
import { Helmet } from "react-helmet-async";
import backgroundImage from "../assets/img/icon/pattern-02.png";
import "../i18n";
import Amenities from "./components/Amenities";
import Corefeature from "./components/Corefeature";
import Footer from "./components/Footer";

const AboutPage = () => {
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
    zIndex: "-1",
  };

  return (
    <div style={sectionStyle}>
      <Helmet>
        <title>Apartments Jasminka Podgora About Page</title>
        <link
          rel="canonical"
          href="https://www.apartments-jasminka-podgora.com/about"
        />
      </Helmet>
      <Corefeature />
      <Amenities />
      <Footer />
    </div>
  );
};

export default AboutPage;
