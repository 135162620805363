import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { deleteBooking, updateBooking } from "../../api/api";

const EventPopup = ({ event, onClose }) => {
  const { id, title, startDate, endDate, description, apartmentId } = event;
  const [error, setError] = useState("");

  const [currentEvent, setCurrentEvent] = useState({
    id: id,
    title: title,
    startDate: new Date(startDate), // Convert to Date object
    endDate: new Date(endDate), // Convert to Date object
    description: description,
    apartmentId: apartmentId,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Convert dates to ISO format before sending to backend
    const updatedEvent = {
      ...currentEvent,
      startDate: moment(currentEvent.startDate).toISOString(),
      endDate: moment(currentEvent.endDate).toISOString(),
    };

    const id = updatedEvent.id;
    delete updatedEvent.id;

    try {
      await updateBooking(id, updatedEvent);
      setError(""); // Reset error state if update is successful
      onClose();
    } catch (error) {
      setError(error.response?.data.detail ?? "An error occurred");
    }
  };

  const handleDelete = async () => {
    await deleteBooking(id);
    onClose();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 border border-gray-300 rounded-lg relative w-full max-w-md">
        <button
          className="absolute top-2 right-2 bg-transparent text-gray-500 hover:text-red-500"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <form className="mt-4" onSubmit={handleSubmit}>
          <label htmlFor="titleInput" className="block mb-2">
            Title
          </label>
          <input
            type="text"
            className="w-full mb-4 border border-gray-300 rounded-md p-2"
            value={currentEvent.title}
            name="titleInput"
            onChange={(e) =>
              setCurrentEvent({ ...currentEvent, title: e.target.value })
            }
          />
          <label htmlFor="descriptionInput" className="block mb-2">
            Description
          </label>
          <input
            type="text"
            className="w-full mb-4 border border-gray-300 rounded-md p-2"
            value={currentEvent.description}
            name="descriptionInput"
            onChange={(e) =>
              setCurrentEvent({ ...currentEvent, description: e.target.value })
            }
          />
          <div className="mb-4">
            <label htmlFor="startDateInput" className="block mb-2">
              Start Date
            </label>
            <DatePicker
              className="w-full border border-gray-300 rounded-md p-2"
              selected={currentEvent.startDate}
              onChange={(date) =>
                setCurrentEvent({ ...currentEvent, startDate: date })
              }
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              name="startDateInput"
              calendarClassName="custom-datepicker"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="endDateInput" className="block mb-2">
              End Date
            </label>
            <DatePicker
              className="w-full border border-gray-300 rounded-md p-2"
              selected={currentEvent.endDate}
              onChange={(date) =>
                setCurrentEvent({ ...currentEvent, endDate: date })
              }
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              name="endDateInput"
              calendarClassName="custom-datepicker"
            />
          </div>

          {error !== "" && <p className="text-red-600 text-md pb-2">{error}</p>}
          <div className="flex justify-between">
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

EventPopup.propTypes = {
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EventPopup;
