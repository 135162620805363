import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApartmentCalendar from "./components/ApartmentCalendar";

const BookingManagement = () => {
  const [activeApartment, setActiveApartment] = useState("1");

  const handleTabChange = (tab) => {
    setActiveApartment(tab);
  };

  return (
    <Tabs
      activeKey={activeApartment}
      onSelect={handleTabChange}
      className="pt-20"
    >
      <Tab eventKey="1" title="Apartment 1">
        {activeApartment === "1" && <ApartmentCalendar apartmentId={1} />}
      </Tab>
      <Tab eventKey="2" title="Apartment 2">
        {activeApartment === "2" && <ApartmentCalendar apartmentId={2} />}
      </Tab>
      <Tab eventKey="3" title="Apartment 3">
        {activeApartment === "3" && <ApartmentCalendar apartmentId={3} />}
      </Tab>
    </Tabs>
  );
};

export default BookingManagement;
