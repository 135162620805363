import { amenitiesWhite, apTwoAmenities } from "./amenities";
import apOneImages from "./ap-one-images";
import apThreeImages from "./ap-three-images";
import apTwoImages from "./ap-two-images";

export const apartments = [
  {
    id: 1,
    name: "Apartment 1",
    persons: "2+3",
    images: apOneImages,
    amenities: amenitiesWhite,
  },
  {
    id: 2,
    name: "Apartment 2 Studio",
    persons: "2",
    images: apTwoImages,
    amenities: apTwoAmenities,
  },
  {
    id: 3,
    name: "Apartment 3",
    persons: "2+3",
    images: apThreeImages,
    amenities: amenitiesWhite,
  },
];
