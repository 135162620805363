import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import backgroundImage from "../../assets/img/icon/pattern-02.png";

const Testimonials = () => {
  const { t } = useTranslation();

  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    width: "100%",
    paddingLeft: "5%",
    paddingRight: "5%",
    overflow: "hidden",
  };

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      style={sectionStyle}
      className="testimonial-section pb-115 pt-115"
      id="testimonials"
    >
      <div className="section-title text-center mb-8">
        <span className="title-tag">{t("testimonialsWord")}</span>
        <h2>{t("guestReviews")}</h2>
      </div>
      <Slider className="row testimonial-slider " {...settings}>
        {t("testimonials", { returnObjects: true }).map((item, i) => (
          <div key={i} className="col-lg-12">
            <div className="testimonial-box">
              <h3>{item.name}</h3>
              <span className="clinet-post">{item.designation}</span>
              <p>{item.desc}</p>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Testimonials;
