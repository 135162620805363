import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { checkAvailability, createBooking, fetchBookings } from "../../api/api";
import EventPopup from "./EventPopup";

const localizer = momentLocalizer(moment);

const ApartmentCalendar = ({ apartmentId }) => {
  const [events, setEvents] = useState([]);
  const [available, setAvailable] = useState("");
  const [error, setError] = useState("");

  const [checkDates, setCheckDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [bookingDetails, setBookingDetails] = useState({
    title: "",
    description: "",
    startDate: new Date(),
    endDate: new Date(),
    apartmentId: apartmentId,
  });

  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleClosePopup = async () => {
    setSelectedEvent(null);
    setEvents(await fetchBookings(apartmentId));
  };

  const handleInputChange = (name, value) => {
    setBookingDetails({
      ...bookingDetails,
      [name]: value,
    });
  };

  const handleCheckChange = (name, value) => {
    setCheckDates({
      ...checkDates,
      [name]: value,
    });
  };

  const handleCheckSubmit = async (e) => {
    e.preventDefault();
    const result = await checkAvailability(
      apartmentId,
      checkDates.startDate,
      checkDates.endDate
    );
    setAvailable(result.available);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createBooking(bookingDetails);
      const bookings = await fetchBookings(apartmentId);
      setEvents(bookings);
    } catch (error) {
      setError(error.response?.data.detail ?? "An error occurred");
      console.error("Error creating booking:", error);
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const bookings = await fetchBookings(apartmentId);
      setEvents(bookings);
    };
    fetchEvents();
  }, [apartmentId]);

  return (
    <div className="container mt-3">
      <Row>
        <Col xs={12} md={6}>
          <p className="bg-gray-50 mb-3 text-gray-900 text-md">
            Input Booking Details
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={bookingDetails.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={bookingDetails.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={bookingDetails.startDate}
                    onChange={(date) => handleInputChange("startDate", date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    required
                    className="form-control"
                    calendarClassName="custom-datepicker"
                    popperPlacement="top-start"
                    popperClassName="custom-datepicker-popper"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={bookingDetails.endDate}
                    onChange={(date) => handleInputChange("endDate", date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    required
                    className="form-control"
                    calendarClassName="custom-datepicker"
                    popperPlacement="top-start"
                    popperClassName="custom-datepicker-popper"
                  />
                </Form.Group>
              </Col>
            </Row>
            {error && (
              <p className="text-danger text-md mb-2">{error.toString()}</p>
            )}
            <Button className="bg-blue-600 border-none mt-2" type="submit">
              Create
            </Button>
          </Form>
        </Col>
        <Col xs={12} md={6}>
          <p className="text-gray-900 text-xl">Calendar</p>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="startDate"
            endAccessor="endDate"
            style={{ height: 500 }}
            titleAccessor={(event) =>
              `${event.title} - ${moment(event.startDate).format(
                "DD.MM.YYYY."
              )} - ${moment(event.endDate).format("DD.MM.YYYY.")}`
            }
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: "#dc2626",
                borderRadius: "5px",
              },
            })}
            onSelectEvent={handleEventClick}
            views={["month"]}
          />
          {selectedEvent && (
            <EventPopup event={selectedEvent} onClose={handleClosePopup} />
          )}
          <Form className="mt-3" onSubmit={handleCheckSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="checkStartDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={checkDates.startDate}
                    onChange={(e) =>
                      handleCheckChange("startDate", e.target.value)
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="checkEndDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={checkDates.endDate}
                    onChange={(e) =>
                      handleCheckChange("endDate", e.target.value)
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button className="bg-blue-600 border-none mt-2" type="submit">
              Check
            </Button>
          </Form>
          {typeof available === "boolean" && (
            <div className="pt-2">
              {available ? (
                <p className="text-success text-md mb-2">Term available</p>
              ) : (
                <p className="text-danger text-md mb-2">Term unavailable</p>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ApartmentCalendar;
