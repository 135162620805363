import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = "https://ivanac-nestjs-calendar-api.fly.dev";
const API = axios.create({
  baseURL: BASE_URL,
});

// Interceptor to add Authorization header to requests
API.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("access_token"); // Read access token from cookies
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const createBooking = async (bookingDetails) => {
  try {
    const response = await API.post(`/bookings`, bookingDetails);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBooking = async (bookingId) => {
  try {
    const response = await API.delete(`/bookings/${bookingId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBookings = async (apartmentId) => {
  console.log("APARTMENT ID ", apartmentId);
  try {
    const response = await API.get(
      `/bookings/apartment?startDate=2024-01-01&endDate=2222-12-31&apartmentId=${apartmentId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkAvailability = async (apartmentId, startDate, endDate) => {
  try {
    const response = await API.get(
      `/bookings/availability?startDate=${startDate}&endDate=${endDate}&apartmentId=${apartmentId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBooking = async (eventId, eventData) => {
  const response = await API.patch(`/bookings/${eventId}`, eventData);
  return response.data;
};

export const signIn = async (username, password) => {
  try {
    const response = await API.post("/auth/login", {
      username,
      password,
    });
    return response.data.accessToken;
  } catch (error) {
    throw new Error("Login failed. Please check your credentials.");
  }
};
