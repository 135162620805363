import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthProvider";
import { signIn } from "../api/api";

function Login() {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoginError("");

    try {
      const token = await signIn(username, password);
      Cookies.set("access_token", token, {
        secure: true,
        // httpOnly: true, // Uncomment if server supports setting HttpOnly
      });
      login();
      navigate("/admin/apartments");
    } catch (error) {
      setLoginError(error.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pt-150 container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <p className="mb-4 text-center font-weight-bold">Sign In</p>
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    id="username"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    type="text"
                    placeholder="Enter your username"
                    className="form-control"
                    aria-label="Username"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    type="password"
                    placeholder="Enter your password"
                    className="form-control"
                    aria-label="Password"
                  />
                  {loginError && (
                    <div className="text-danger">{loginError}</div>
                  )}
                </div>

                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={loading}
                    aria-busy={loading ? "true" : "false"}
                  >
                    {loading ? "Signing In..." : "Sign In"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
