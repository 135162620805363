import ap2_7Thumb from "../assets/img/ap2webp-compressed/11-min-thumb.webp";
import ap2_7 from "../assets/img/ap2webp-compressed/11-min.webp";
import ap2_8Thumb from "../assets/img/ap2webp-compressed/12-min-thumb.webp";
import ap2_8 from "../assets/img/ap2webp-compressed/12-min.webp";
import ap2_1Thumb from "../assets/img/ap2webp-compressed/2-min-thumb.webp";
import ap2_1 from "../assets/img/ap2webp-compressed/2-min.webp";
import ap2_2Thumb from "../assets/img/ap2webp-compressed/3-min-thumb.webp";
import ap2_2 from "../assets/img/ap2webp-compressed/3-min.webp";
import ap2_3Thumb from "../assets/img/ap2webp-compressed/4-min-thumb.webp";
import ap2_3 from "../assets/img/ap2webp-compressed/4-min.webp";
import ap2_4Thumb from "../assets/img/ap2webp-compressed/6-min-thumb.webp";
import ap2_4 from "../assets/img/ap2webp-compressed/6-min.webp";
import ap2_5Thumb from "../assets/img/ap2webp-compressed/7-min-thumb.webp";
import ap2_5 from "../assets/img/ap2webp-compressed/7-min.webp";
import ap2_6Thumb from "../assets/img/ap2webp-compressed/8-min-thumb.webp";
import ap2_6 from "../assets/img/ap2webp-compressed/8-min.webp";

const apTwoImages = [
  {
    original: ap2_1,
    thumbnail: ap2_1Thumb,
  },
  {
    original: ap2_2,
    thumbnail: ap2_2Thumb,
  },
  {
    original: ap2_3,
    thumbnail: ap2_3Thumb,
  },
  {
    original: ap2_4,
    thumbnail: ap2_4Thumb,
  },
  {
    original: ap2_5,
    thumbnail: ap2_5Thumb,
  },
  {
    original: ap2_6,
    thumbnail: ap2_6Thumb,
  },
  {
    original: ap2_7,
    thumbnail: ap2_7Thumb,
  },
  {
    original: ap2_8,
    thumbnail: ap2_8Thumb,
  },
];

export default apTwoImages;
