import { motion } from "framer-motion"; // Import motion from framer-motion
import React from "react";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/img/icon/pattern-02.png";
import Gallery from "../components/Gallery";

const PodgoraHead = () => {
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
  };
  const { t } = useTranslation();

  return (
    <section style={sectionStyle} id="about" className="text-block pt-20 pb-68">
      <div className="container">
        {/*      <motion.div
          initial={{ opacity: 0, x: -100 }} // Initial animation state
          animate={{ opacity: 1, x: 0 }} // Animation to apply
          transition={{ delay: 0.3, duration: 1 }} // Delay before animation starts
        >
          <div className="section-title mb-8 text-center">
            <span className="title-tag">welcome to </span>
            <h2>{t("nav4")}</h2>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="text-img text-center text-lg-left mb-small">
              <img src={skywalk} alt="" />
            </div>
          </div>
        </motion.div> */}
        <div className="section-title mb-8 text-center">
          <span className="title-tag">{t("welcomeTo")}</span>
          <h2>{t("toPodgora")}</h2>
        </div>
        <div className="row mt-10 align-items-center bg-white pt-10 pb-5 justify-content-center">
          <motion.div
            initial={{ opacity: 0, x: -100 }} // Initial animation state
            animate={{ opacity: 1, x: 0 }} // Animation to apply
            transition={{ delay: 0.3 }} // Delay before animation starts
            className="col-lg-10 col-md-8 col-sm-10"
          >
            <div className="section-title text-center mb-8">
              <span className="title-tag">{t("podgoraTextHead")}</span>
            </div>
            <div className="block-text">
              <p style={{ whiteSpace: "pre-line" }}>{t("aboutPodgora")}</p>
            </div>
          </motion.div>
        </div>
        <div className="row mt-10 align-items-center pt-5 pb-20 justify-content-center">
          <Gallery />
        </div>
      </div>
    </section>
  );
};

export default PodgoraHead;
