import ap3_1 from "../assets/img/ap3webp-compressed/1.webp";
import ap3_10 from "../assets/img/ap3webp-compressed/10.webp";
import ap3_11 from "../assets/img/ap3webp-compressed/11.webp";
import ap3_12 from "../assets/img/ap3webp-compressed/12.webp";
import ap3_13 from "../assets/img/ap3webp-compressed/13.webp";
import ap3_14 from "../assets/img/ap3webp-compressed/14.webp";
import ap3_15 from "../assets/img/ap3webp-compressed/15.webp";
import ap3_16 from "../assets/img/ap3webp-compressed/16.webp";
import ap3_17 from "../assets/img/ap3webp-compressed/17.webp";
import ap3_18 from "../assets/img/ap3webp-compressed/18.webp";
import ap3_19 from "../assets/img/ap3webp-compressed/19.webp";
import ap3_2 from "../assets/img/ap3webp-compressed/2.webp";
import ap3_20 from "../assets/img/ap3webp-compressed/20.webp";
import ap3_21 from "../assets/img/ap3webp-compressed/21.webp";
import ap3_22 from "../assets/img/ap3webp-compressed/22.webp";
import ap3_3 from "../assets/img/ap3webp-compressed/3.webp";
import ap3_4 from "../assets/img/ap3webp-compressed/4.webp";
import ap3_5 from "../assets/img/ap3webp-compressed/5.webp";
import ap3_6 from "../assets/img/ap3webp-compressed/6.webp";
import ap3_7 from "../assets/img/ap3webp-compressed/7.webp";
import ap3_8 from "../assets/img/ap3webp-compressed/8.webp";
import ap3_9 from "../assets/img/ap3webp-compressed/9.webp";

import ap3_1Thumb from "../assets/img/ap3webp-compressed/1-thumb.webp";
import ap3_10Thumb from "../assets/img/ap3webp-compressed/10-thumb.webp";
import ap3_11Thumb from "../assets/img/ap3webp-compressed/11-thumb.webp";
import ap3_12Thumb from "../assets/img/ap3webp-compressed/12-thumb.webp";
import ap3_13Thumb from "../assets/img/ap3webp-compressed/13-thumb.webp";
import ap3_14Thumb from "../assets/img/ap3webp-compressed/14-thumb.webp";
import ap3_15Thumb from "../assets/img/ap3webp-compressed/15-thumb.webp";
import ap3_16Thumb from "../assets/img/ap3webp-compressed/16-thumb.webp";
import ap3_17Thumb from "../assets/img/ap3webp-compressed/17-thumb.webp";
import ap3_18Thumb from "../assets/img/ap3webp-compressed/18-thumb.webp";
import ap3_19Thumb from "../assets/img/ap3webp-compressed/19-thumb.webp";
import ap3_2Thumb from "../assets/img/ap3webp-compressed/2-thumb.webp";
import ap3_20Thumb from "../assets/img/ap3webp-compressed/20-thumb.webp";
import ap3_21Thumb from "../assets/img/ap3webp-compressed/21-thumb.webp";
import ap3_22Thumb from "../assets/img/ap3webp-compressed/22-thumb.webp";
import ap3_3Thumb from "../assets/img/ap3webp-compressed/3-thumb.webp";
import ap3_4Thumb from "../assets/img/ap3webp-compressed/4-thumb.webp";
import ap3_5Thumb from "../assets/img/ap3webp-compressed/5-thumb.webp";
import ap3_6Thumb from "../assets/img/ap3webp-compressed/6-thumb.webp";
import ap3_7Thumb from "../assets/img/ap3webp-compressed/7-thumb.webp";
import ap3_8Thumb from "../assets/img/ap3webp-compressed/8-thumb.webp";
import ap3_9Thumb from "../assets/img/ap3webp-compressed/9-thumb.webp";

const apThreeImages = [
  {
    original: ap3_1,
    thumbnail: ap3_1Thumb,
  },
  {
    original: ap3_2,
    thumbnail: ap3_2Thumb,
  },
  {
    original: ap3_3,
    thumbnail: ap3_3Thumb,
  },
  {
    original: ap3_4,
    thumbnail: ap3_4Thumb,
  },
  {
    original: ap3_5,
    thumbnail: ap3_5Thumb,
  },
  {
    original: ap3_6,
    thumbnail: ap3_6Thumb,
  },
  {
    original: ap3_7,
    thumbnail: ap3_7Thumb,
  },
  {
    original: ap3_8,
    thumbnail: ap3_8Thumb,
  },
  {
    original: ap3_9,
    thumbnail: ap3_9Thumb,
  },
  {
    original: ap3_10,
    thumbnail: ap3_10Thumb,
  },
  {
    original: ap3_11,
    thumbnail: ap3_11Thumb,
  },
  {
    original: ap3_12,
    thumbnail: ap3_12Thumb,
  },
  {
    original: ap3_13,
    thumbnail: ap3_13Thumb,
  },
  {
    original: ap3_14,
    thumbnail: ap3_14Thumb,
  },
  {
    original: ap3_15,
    thumbnail: ap3_15Thumb,
  },
  {
    original: ap3_16,
    thumbnail: ap3_16Thumb,
  },
  {
    original: ap3_17,
    thumbnail: ap3_17Thumb,
  },
  {
    original: ap3_18,
    thumbnail: ap3_18Thumb,
  },
  {
    original: ap3_19,
    thumbnail: ap3_19Thumb,
  },
  {
    original: ap3_20,
    thumbnail: ap3_20Thumb,
  },
  {
    original: ap3_21,
    thumbnail: ap3_21Thumb,
  },
  {
    original: ap3_22,
    thumbnail: ap3_22Thumb,
  },
];

export default apThreeImages;
