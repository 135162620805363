import { motion } from "framer-motion"; // Import motion from framer-motion
import React from "react";
import { useTranslation } from "react-i18next";
import homeImg from "../../assets/img/home.jpg";
import backgroundImage from "../../assets/img/icon/pattern-02.png";

const About = () => {
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
  };
  const { t } = useTranslation();

  return (
    <section
      style={sectionStyle}
      id="about"
      className="text-block pt-115 pb-115"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-7">
            <div className="text-img text-center text-lg-left mb-small">
              <img src={homeImg} alt="" />
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, x: -100 }} // Initial animation state
            animate={{ opacity: 1, x: 0 }} // Animation to apply
            transition={{ delay: 0.3 }} // Delay before animation starts
            className="col-lg-5 col-md-8 col-sm-10"
          >
            <div className="block-text">
              <div className="section-title mb-20">
                <h3>{t("aboutTitle")}</h3>
              </div>
              <p>{t("about")}</p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
