import React, { createContext, useContext, useMemo } from "react";
import { createRoot } from "react-dom/client";

import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"; // Correct import path
import Home from "./pages/Home";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";
import { HelmetProvider } from "react-helmet-async";
import AuthProvider, { AuthContext } from "./AuthProvider";
import "./assets/css/animate.min.css";
import "./assets/css/default.css";
import "./assets/css/flaticon.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/style.css";
import { apartments } from "./constants/apartments";
import AboutPage from "./pages/AboutPage";
import ApartmentDetail from "./pages/ApartmentDetail";
import Apartments from "./pages/Apartments";
import BookingManagement from "./pages/BookingManagement";
import Login from "./pages/Login";
import Podgora from "./pages/Podgora";
import Navbar from "./pages/components/Navbar";

export const ApiContext = createContext({});

function NotLoggedInRoute({ children }) {
  const { isLoggedIn } = useContext(AuthContext);
  return isLoggedIn ? <Navigate to="/admin/apartments" /> : children;
}

function PrivateRoute({ children }) {
  const { isLoggedIn } = useContext(AuthContext);
  return isLoggedIn ? children : <Navigate to="/admin/login" />;
}

export const ApiProvider = () => {
  const value = useMemo(() => ({}), []);

  const helmetContext = {};

  return (
    <ApiContext.Provider value={value}>
      <AuthProvider>
        <HelmetProvider context={helmetContext}>
          <Navbar />
          <Outlet />
        </HelmetProvider>
      </AuthProvider>
    </ApiContext.Provider>
  );
};

// Main route configuration
const router = createBrowserRouter([
  {
    element: <ApiProvider />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "apartments",
        element: <Apartments />,
      },
      {
        path: "apartments/1",
        element: (
          <ApartmentDetail
            persons={apartments[0].persons}
            amenities={apartments[0].amenities}
            apartmentId={apartments[0].id}
            images={apartments[0].images}
          />
        ),
      },
      {
        path: "apartments/2",
        element: (
          <ApartmentDetail
            persons={apartments[1].persons}
            amenities={apartments[1].amenities}
            apartmentId={apartments[1].id}
            images={apartments[1].images}
          />
        ),
      },
      {
        path: "apartments/3",
        element: (
          <ApartmentDetail
            persons={apartments[2].persons}
            amenities={apartments[2].amenities}
            apartmentId={apartments[2].id}
            images={apartments[2].images}
          />
        ),
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "podgora",
        element: <Podgora />,
      },
      {
        path: "admin/login",
        element: (
          <NotLoggedInRoute>
            <Login />
          </NotLoggedInRoute>
        ),
      },
      {
        path: "admin/apartments",
        element: (
          <PrivateRoute>
            <BookingManagement />
          </PrivateRoute>
        ),
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

serviceWorker.unregister();
