import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const FeatureElement = ({ title, about, icon }) => (
  <motion.div
    initial={{ opacity: 0, x: -20 }} // Initial animation state
    animate={{ opacity: 1, x: 0 }} // Animation to apply
    transition={{ delay: 0.3 }} // Delay animation
    className="feature-box with-hover-img"
  >
    <div className="icon">{icon}</div>
    <h3>
      <Link to="#">{title}</Link>
    </h3>
    <p>{about}</p>
    <div
      className="hover-img"
      style={{ backgroundImage: "url(assets/img/feature/01.jpg)" }}
    />
  </motion.div>
);

FeatureElement.propTypes = {
  title: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default FeatureElement;
