import emailjs from "@emailjs/browser";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import backgroundImage from "../../assets/img/icon/pattern-02.png";

const Footer = () => {
  const { t } = useTranslation();
  const [isTop, setIsTop] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("gmail123", "template_6tgome7", e.target, {
        publicKey: "aowLTPaEiV1VRfSoo",
      })
      .then();
    e.target.reset();
    setShow(true);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsTop(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll, false);
    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  const className = isTop ? "active" : "";

  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
  };

  return (
    <div id="contact">
      {/*====== Back to Top ======*/}
      <Link
        to="#"
        className={`back-to-top ${className}`}
        id="backToTop"
        onClick={scrollToTop}
      >
        <i className="fal fa-angle-double-up" />
      </Link>
      {/*====== FOOTER START ======*/}
      <footer className="footer-two" style={sectionStyle}>
        <div style={sectionStyle} className="footer-widget-area pt-100 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-6 order-1">
                {/* Site Info Widget */}
                <div className="contact-maps mb-30">
                  <iframe
                    title="gmap"
                    className="map"
                    width="400"
                    height="500"
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.340396841631!2d17.069316576383194!3d43.24428727112454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134af03ed3ab539d%3A0xe5e99bfcc592959f!2sJasminka%20apartments!5e0!3m2!1sen!2shr!4v1712508972756!5m2!1sen!2shr"
                  ></iframe>
                  <h3 className="pt-4">{t("contact")}</h3>
                  <p className="font-bold">Ivan Ivanac</p>
                  <p className="font-bold">
                    Obala Petra Krešimira IV 65, 21327 Podgora, {t("croatia")}
                  </p>
                  <p className="font-bold">
                    {t("mobile")}: +385 (0)98 920 3826
                  </p>
                  <p className="font-bold">
                    Email: jasminka.apartments@gmail.com
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 order-2 order-lg-3">
                <div className="room-booking-form">
                  <h3 className="title">{t("form")}</h3>
                  <form onSubmit={sendEmail}>
                    <div className="input-group input-group-two left-icon mb-10">
                      <input
                        className="input"
                        type="text"
                        placeholder={t("name")}
                        name="userName"
                        id="userName"
                        required
                      />
                    </div>
                    <div className="input-group input-group-two left-icon mb-10">
                      <input
                        className="input"
                        type="email"
                        placeholder={t("email")}
                        name="userEmail"
                        id="userEmail"
                        required
                      />
                    </div>
                    <div className="input-group input-group-two left-icon mb-10">
                      <input
                        className="input"
                        type="date"
                        name="startDate"
                        id="startDate"
                        required
                      />
                    </div>
                    <div className="input-group input-group-two left-icon mb-10">
                      <input
                        className="input"
                        type="date"
                        name="endDate"
                        id="endDate"
                        required
                      />
                    </div>
                    <div className="input-group input-group-two left-icon mb-10">
                      <textarea
                        className="input"
                        type="textarea"
                        placeholder={t("msg")}
                        name="message"
                        id="userMsg"
                      />
                    </div>
                    <div className="input-group">
                      <button className="main-btn btn-filled banner-btn">
                        {t("form")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*====== FOOTER END ======*/}
      <Modal style={{ rounded: "none" }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("modalHead")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("modalBody")}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleClose}
            className="main-btn btn-filled banner-btn"
          >
            {t("modalClose")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Footer;
