import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import backgroundImage from "../assets/img/icon/pattern-02.png";
import { apartments } from "../constants/apartments";
import ApartmentCard from "./components/ApartmentCard";
import Footer from "./components/Footer";

function Apartments() {
  const [apartmentsData, setApartmentsData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setApartmentsData(apartments);
  }, []);

  const sectionStyle = {
    paddingTop: "100px",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
  };

  return (
    <motion.section
      style={sectionStyle}
      className="py-10 md:py-20 lg:py-32"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Helmet>
        <title>Apartments Jasminka Podgora Apartments Page</title>
        <link
          rel="canonical"
          href="https://www.apartments-jasminka-podgora.com/apartments"
        />
      </Helmet>
      <div className="container mb-100">
        <div className="section-title text-center mb-8 md:mb-12 lg:mb-16">
          <h2 className="text-3xl md:text-4xl lg:text-5xl">
            {t("apartments")}
          </h2>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {apartmentsData.slice(0, 3).map((apartment, i) => (
            <div key={i} className="col">
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: 1.1, transition: 0 }}
              >
                <ApartmentCard
                  id={apartment.id}
                  persons={apartment.persons}
                  imageUrl={apartment.images[0].thumbnail}
                />
              </motion.div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </motion.section>
  );
}

export default Apartments;
