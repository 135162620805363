import img0 from "../assets/img/Skywalk_Biokovo.jpg";
import img18Thumb from "../assets/img/vanka-compressed/112-thumb.webp";
import img18 from "../assets/img/vanka-compressed/112.webp";
import img17Thumb from "../assets/img/vanka-compressed/113-thumb.webp";
import img17 from "../assets/img/vanka-compressed/113.webp";
import {
  default as img12Thumb,
  default as img16Thumb,
} from "../assets/img/vanka-compressed/115-thumb.webp";
import {
  default as img12,
  default as img16,
} from "../assets/img/vanka-compressed/115.webp";
import img15Thumb from "../assets/img/vanka-compressed/117-thumb.webp";
import img15 from "../assets/img/vanka-compressed/117.webp";
import img14Thumb from "../assets/img/vanka-compressed/118-thumb.webp";
import img14 from "../assets/img/vanka-compressed/118.webp";
import img13Thumb from "../assets/img/vanka-compressed/12-min-thumb.webp";
import img13 from "../assets/img/vanka-compressed/12-min.webp";
import img11Thumb from "../assets/img/vanka-compressed/17-thumb.webp";
import img11 from "../assets/img/vanka-compressed/17.webp";
import img10Thumb from "../assets/img/vanka-compressed/18-thumb.webp";
import img10 from "../assets/img/vanka-compressed/18.webp";
import img9Thumb from "../assets/img/vanka-compressed/19-thumb.webp";
import img9 from "../assets/img/vanka-compressed/19.webp";
import img7Thumb from "../assets/img/vanka-compressed/20-thumb.webp";
import img7 from "../assets/img/vanka-compressed/20.webp";
import img6Thumb from "../assets/img/vanka-compressed/66-min-thumb.webp";
import img6 from "../assets/img/vanka-compressed/66-min.webp";
import img5Thumb from "../assets/img/vanka-compressed/71-min-thumb.webp";
import img5 from "../assets/img/vanka-compressed/71-min.webp";
/* import img4Thumb from "../assets/img/vanka-compressed/81-min-thumb.webp";
import img4 from "../assets/img/vanka-compressed/81-min.webp";
import img3Thumb from "../assets/img/vanka-compressed/87-min-thumb.webp";
import img3 from "../assets/img/vanka-compressed/87-min.webp";
import img2Thumb from "../assets/img/vanka-compressed/97-min-thumb.webp";
import img2 from "../assets/img/vanka-compressed/97-min.webp"; */

const galleryImages = [
  /*   {
    original: img1,
    thumbnail: img1Thumb,
  },
  {
    original: img2,
    thumbnail: img2Thumb,
  },
  {
    original: img3,
    thumbnail: img3Thumb,
  },
  {
    original: img4,
    thumbnail: img4Thumb,
  }, */
  {
    original: img0,
    thumbnail: img0,
  },
  {
    original: img5,
    thumbnail: img5Thumb,
  },
  {
    original: img6,
    thumbnail: img6Thumb,
  },
  {
    original: img7,
    thumbnail: img7Thumb,
  },
  {
    original: img9,
    thumbnail: img9Thumb,
  },
  {
    original: img10,
    thumbnail: img10Thumb,
  },
  {
    original: img11,
    thumbnail: img11Thumb,
  },
  {
    original: img12,
    thumbnail: img12Thumb,
  },
  {
    original: img13,
    thumbnail: img13Thumb,
  },
  {
    original: img14,
    thumbnail: img14Thumb,
  },
  {
    original: img15,
    thumbnail: img15Thumb,
  },
  {
    original: img16,
    thumbnail: img16Thumb,
  },
  {
    original: img17,
    thumbnail: img17Thumb,
  },
  {
    original: img18,
    thumbnail: img18Thumb,
  },
];

export default galleryImages;
