import React from "react";
import { Helmet } from "react-helmet-async";
import "../i18n";
import About from "./components/About";
import Mainbanner from "./components/Banner";
import Footer from "./components/Footer";
import Testimonials from "./components/Testimonials";
import VideoBlock from "./components/Videoblock";

function Home() {
  return (
    <div>
      <Helmet>
        <title>Apartments Jasminka Podgora Home Page</title>
        <link
          rel="canonical"
          href="https://www.apartments-jasminka-podgora.com"
        />
      </Helmet>
      <Mainbanner />
      <About />
      <VideoBlock />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default Home;
