import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

const VideoBlock = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <section
      className="text-block bg-black with-pattern pt-115 pb-115"
      id="explore"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-10 order-2 order-lg-1">
            <div className="block-text">
              <div className="section-title mb-20">
                <h2>{t("videoTitle")}</h2>
              </div>
              <p className="pr-50">{t("videoAbout")}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 order-1 order-lg-2">
            <div
              className="video-wrap video-wrap-two mb-small"
              style={{
                backgroundImage: "url(assets/img/text-block/03.jpg)",
              }}
            >
              <div className="popup-video" onClick={onOpenModal}>
                <i className="fas fa-play" />
              </div>
              <Modal
                open={open}
                onClose={onCloseModal}
                center
                classNames={{
                  modal: "video-modal",
                }}
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/wY88iOc8p20?si=CzgUuMYj6MTT06d0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <div className="pattern-wrap">
        <div className="pattern" />
      </div>
    </section>
  );
};

export default VideoBlock;
