import { motion } from "framer-motion"; // Import motion from framer-motion
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import bannerimg1 from "../../assets/img/gallery/podgora.jpg";

const bannerPost = {
  photo: bannerimg1,
  tag: "The ultimate luxury experience",
  taganimation: ".6s",
  title: "Welcome to Villa Gojun",
  titleanimation: ".9s",
  btn1: "take a tour",
  btn1animation: "1.1s",
  btn1url: "gallery",
  btn2: "Learn More",
  btn2animation: "1.3s",
  btn2url: "about",
};

const Banner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const bannerItem = bannerPost;

  return (
    <div id="home" className="banner-area">
      <img
        className="d-block w-100 h-screen background"
        src={bannerItem.photo}
        alt="Banner"
        style={{
          objectFit: "cover",
          objectPosition: "center",
          height: "100vh",
        }}
      />

      <motion.div // Wrap in motion.div for animations
        initial={{ opacity: 0, y: 50 }} // Initial animation state
        animate={{ opacity: 1, y: 0 }} // Animation to apply
        transition={{ delay: 0.5 }} // Delay before animation starts
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="banner-content text-center">
                <motion.h1 // Apply animation to title using motion.h1
                  initial={{ opacity: 0, y: 50 }} // Initial animation state
                  animate={{ opacity: 1, y: 0 }} // Animation to apply
                  transition={0} // Delay before animation starts
                  className="title"
                >
                  {t("title")}
                </motion.h1>
                <ul>
                  <Row>
                    <Col xs={6} md={6}>
                      <motion.li // Apply animation to btn1 using motion.li
                        initial={{ opacity: 0, y: 50 }} // Initial animation state
                        animate={{ opacity: 1, y: 0 }} // Animation to apply
                        transition={{
                          delay: parseFloat(bannerItem.btn1animation),
                        }} // Delay before animation starts
                      >
                        <p
                          className="main-btn btn-filled banner-btn"
                          onClick={() => {
                            navigate("/about");
                          }}
                        >
                          {t("btn1")}
                        </p>
                      </motion.li>
                    </Col>
                    <Col xs={6} md={6}>
                      <motion.li // Apply animation to btn2 using motion.li
                        initial={{ opacity: 0, y: 50 }} // Initial animation state
                        animate={{ opacity: 1, y: 0 }} // Animation to apply
                        transition={{
                          delay: parseFloat(bannerItem.btn2animation),
                        }} // Delay before animation starts
                      >
                        <Link
                          className="main-btn btn-filled banner-btn"
                          to="about"
                          smooth={true}
                        >
                          {t("btn2")}
                        </Link>
                      </motion.li>
                    </Col>
                  </Row>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Banner;
