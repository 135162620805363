import React from "react";
import { Helmet } from "react-helmet-async";
import backgroundImage from "../assets/img/icon/pattern-02.png";
import "../i18n";
import DistanceTable from "./components/DistanceTable";
import Footer from "./components/Footer";
import PodgoraHead from "./components/PodgoraHead";

const Podgora = () => {
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
    zIndex: "-1",
  };

  return (
    <div style={sectionStyle}>
      <Helmet>
        <title>Apartments Jasminka Podgora Page</title>
        <link
          rel="canonical"
          href="https://www.apartments-jasminka-podgora.com/podgora"
        />
      </Helmet>
      <PodgoraHead />

      <DistanceTable />
      <Footer />
    </div>
  );
};

export default Podgora;
