import acIconWhite from "../assets/img/icon/air-conditioner-white.png";
import acIcon from "../assets/img/icon/air-conditioner.png";
import seaViewIconWhite from "../assets/img/icon/beach-white.png";
import seaViewIcon from "../assets/img/icon/beach.png";
import dishwasherIconWhite from "../assets/img/icon/dishwasher-white.png";
import dishwasherIcon from "../assets/img/icon/dishwasher.png";
import fridgeIconWhite from "../assets/img/icon/fridge-white.png";
import fridgeIcon from "../assets/img/icon/fridge.png";
import kettleIconWhite from "../assets/img/icon/kettle-white.png";
import kettleIcon from "../assets/img/icon/kettle.png";
import kitchenIconWhite from "../assets/img/icon/kitchen-white.png";
import kitchenIcon from "../assets/img/icon/kitchen.png";
import microwaveIconWhite from "../assets/img/icon/microwave-white.png";
import microwaveIcon from "../assets/img/icon/microwave.png";
import parkingIconWhite from "../assets/img/icon/parking-white.png";
import parkingIcon from "../assets/img/icon/parking.png";
import laundryMachineIconWhite from "../assets/img/icon/washing-machine-white.png";
import laundryMachineIcon from "../assets/img/icon/washing-machine.png";
import wifiIconWhite from "../assets/img/icon/wifi-white.png";
import wifiIcon from "../assets/img/icon/wifi.png";

export const apOneAmenities = [
  {
    name: "Wifi",
    url: wifiIcon,
  },
  {
    name: "Parking",
    url: parkingIcon,
  },
  {
    name: "Air Conditioning",
    url: acIcon,
  },
  {
    name: "Sea ViewConditioning",
    url: seaViewIcon,
  },
  {
    name: "Dishwasher",
    url: dishwasherIcon,
  },
  {
    name: "Refrigerator",
    url: fridgeIcon,
  },
  {
    name: "Kettle",
    url: kettleIcon,
  },
  {
    name: "Kitchen",
    url: kitchenIcon,
  },
  {
    name: "Laundry Machine",
    url: laundryMachineIcon,
  },
  {
    name: "Microwave",
    url: microwaveIcon,
  },
];

export const apTwoAmenities = [
  {
    name: "Wifi",
    url: wifiIconWhite,
  },
  {
    name: "Parking",
    url: parkingIconWhite,
  },
  {
    name: "Air Conditioning",
    url: acIconWhite,
  },
  {
    name: "Sea ViewConditioning",
    url: seaViewIconWhite,
  },
  {
    name: "Refrigerator",
    url: fridgeIconWhite,
  },
  {
    name: "Kettle",
    url: kettleIconWhite,
  },
  {
    name: "Kitchen",
    url: kitchenIconWhite,
  },
];

export const amenitiesWhite = [
  {
    name: "Wifi",
    url: wifiIconWhite,
  },
  {
    name: "Parking",
    url: parkingIconWhite,
  },
  {
    name: "Air Conditioning",
    url: acIconWhite,
  },
  {
    name: "Sea ViewConditioning",
    url: seaViewIconWhite,
  },
  {
    name: "Dishwasher",
    url: dishwasherIconWhite,
  },
  {
    name: "Refrigerator",
    url: fridgeIconWhite,
  },
  {
    name: "Kettle",
    url: kettleIconWhite,
  },
  {
    name: "Kitchen",
    url: kitchenIconWhite,
  },
  {
    name: "Laundry Machine",
    url: laundryMachineIconWhite,
  },
  {
    name: "Microwave",
    url: microwaveIconWhite,
  },
];
