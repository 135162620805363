import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import ReactImageGallery from "react-image-gallery";
import { checkAvailability, fetchBookings } from "../api/api";
import backgroundImage from "../assets/img/icon/pattern-02.png";
import Footer from "./components/Footer";

const localizer = momentLocalizer(moment);

const ApartmentDetail = ({ persons, apartmentId, images, amenities }) => {
  const { t } = useTranslation();
  const [checkDates, setCheckDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [available, setAvailable] = useState("");

  const handleCheckChange = (name, value) => {
    setCheckDates({
      ...checkDates,
      [name]: value,
    });
  };

  const handleCheckSubmit = async (e) => {
    e.preventDefault();
    const result = await checkAvailability(
      apartmentId,
      checkDates.startDate,
      checkDates.endDate
    );
    setAvailable(result.available);
  };

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const bookings = await fetchBookings(apartmentId);
      setEvents(bookings);
    };
    fetchEvents();
  }, [apartmentId]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="pt-40"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
        height: "100%",
        width: "100%",
        zIndex: "-1",
        paddingTop: "100px",
      }}
    >
      <Helmet>
        <title>Apartments Jasminka Podgora Apartment Detail Page</title>
        <link
          rel="canonical"
          href={`https://www.apartments-jasminka-podgora.com/apartments/${apartmentId}`}
        />
      </Helmet>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.3 }}
        className="max-w-screen-lg bg-white shadow-md mx-auto px-4 sm:px-6 lg:px-8 py-10 flex flex-col md:flex-row"
      >
        <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="max-w-full overflow-hidden"
          >
            <ReactImageGallery items={images} />
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
          className="w-full md:w-1/2 pl-0 md:pl-8 md:ml-8"
        >
          <h2
            style={{ fontSize: "40px" }}
            className="text-3xl font-large text-golden-50 mb-2"
          >
            {t(`apartment${apartmentId}.title`)}
          </h2>
          <div>
            <span className="inline-block bg-golden-50 rounded-full px-3 py-1 text-sm text-black mr-2">
              {persons} {t("persons")}
            </span>
          </div>
          <div className="pt-4 pb-2">
            {t(`apartment${apartmentId}.description`)}
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="max-w-screen-lg bg-white shadow-md mx-auto px-4 sm:px-6 lg:px-8 py-10 mb-20"
      >
        <div className="pt-2 pb-2">
          <h2 className="mb-4 text-center">{t("amenities")}</h2>
          <div className="flex overflow-x-auto">
            {amenities.map((amenity, i) => (
              <div
                key={i}
                className="feature-box with-hover-img flex-shrink-0 w-30 h-20 h-auto my-4 bg-golden-50 shadow-md flex flex-col items-center p-4 mr-4 transition duration-300 transform hover:scale-105"
              >
                <img
                  src={amenity.url}
                  alt={amenity.name}
                  className="h-24 object-contain"
                />
                <h4 className="text-md pt-4 text-center text-white transition duration-300 hover:text-black">
                  {t(`apartment1.amenity${i}`)}
                </h4>
              </div>
            ))}
          </div>
        </div>
        <div className="pt-2 pb-2">
          <h2 className="mb-4 text-center">{t("availability")}</h2>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="startDate"
            endAccessor="endDate"
            style={{ height: 500 }}
            titleAccessor={() => ``}
            eventPropGetter={() => ({
              style: {
                backgroundColor: "#dc2626",
                opacity: "0.5",
                borderRadius: "0px",
                width: "100%",
                height: "40px",
                WebkitClipPath:
                  "polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0)",
                clipPath: "polygon(0 0, 0 100%, 100% 100%, 100% 100%, 95% 0)",
              },
            })}
            views={["month"]}
          />
          <Form className="mt-3" onSubmit={handleCheckSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="checkStartDate">
                  <Form.Control
                    className="rounded-none"
                    type="date"
                    value={checkDates.startDate}
                    onChange={(e) =>
                      handleCheckChange("startDate", e.target.value)
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="checkEndDate">
                  <Form.Control
                    className="rounded-none"
                    type="date"
                    value={checkDates.endDate}
                    onChange={(e) =>
                      handleCheckChange("endDate", e.target.value)
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Button
                  style={{ height: "70px", width: "100%" }}
                  className="main-btn btn-filled"
                  type="submit"
                >
                  {t("checkAvailability")}
                </Button>
              </Col>
            </Row>
          </Form>
          {typeof available === "boolean" && (
            <div className="pt-2">
              {available ? (
                <p className="text-success text-md mb-2">
                  {t("termAvailable")}
                </p>
              ) : (
                <p className="text-danger text-md mb-2">
                  {t("termUnavailable")}
                </p>
              )}
            </div>
          )}
        </div>
      </motion.div>
      <Footer />
    </motion.div>
  );
};

export default ApartmentDetail;
