import React from "react";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/img/icon/pattern-02.png";

const DistanceTable = () => {
  const { t } = useTranslation();

  const distances = [
    { place: t("sea"), distance: "10 m" },
    { place: t("beach"), distance: "150 m" },
    { place: t("supermarket"), distance: "50 m" },
    { place: t("restaurant"), distance: "70 m" },
    { place: t("emergencyAmbulance"), distance: "8 km" },
    { place: t("pharmacy"), distance: "400 m" },
    { place: t("touristOffice"), distance: "450 m" },
    { place: t("postOffice"), distance: "550 m" },
    { place: t("currencyExchange"), distance: "450 m" },
    { place: t("atm"), distance: "450 m" },
    { place: t("splitAirport"), distance: "105 km" },
    { place: t("dubrovnikAirport"), distance: "167 km" },
    { place: t("zadarAirport"), distance: "200 km" },
    { place: t("ferry"), distance: "9.6 km" },
    { place: t("biokovo"), distance: "7 km" },
  ];

  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
  };
  return (
    <section style={sectionStyle} id="about" className="text-block pb-115">
      <div className="container mx-auto p-4 row mt-10 align-items-center pt-10 pb-20 justify-content-center">
        <div className="overflow-x-auto col-lg-10 col-md-8 col-sm-10">
          <table className="min-w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b-2 border-gray-300 text-left">
                  {t("location")}
                </th>
                <th className="py-2 px-4 border-b-2 border-gray-300 text-left">
                  {t("distance")}
                </th>
              </tr>
            </thead>
            <tbody>
              {distances.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : ""}>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {item.place}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {item.distance}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default DistanceTable;
