import { useTranslation } from "react-i18next";
import { amenitiesWhite } from "../../constants/amenities";

const Amenities = () => {
  const sectionStyle = {
    backgroundImage: "inherit",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
  };
  const { t } = useTranslation();

  return (
    <section style={sectionStyle} id="amenities">
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 pb-10">
        <div className="pt-2 pb-2">
          <div className="section-title text-center mb-50">
            <h2>{t("amenities")}</h2>
          </div>
          <div className="flex overflow-x-auto">
            {amenitiesWhite.map((amenity, i) => (
              <div
                key={i}
                className="feature-box with-hover-img flex-shrink-0 w-48 h-auto my-4 bg-golden-50  shadow-md flex flex-col items-center p-4 mr-4 transition duration-300 transform hover:scale-105"
              >
                <img
                  src={amenity.url}
                  alt={amenity.name}
                  className="h-24 object-contain"
                />
                <h4 className="text-md pt-4 text-center text-white transition duration-300 hover:text-black">
                  {t(`apartment1.amenity${i}`)}
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Amenities;
