import { motion } from "framer-motion"; // Import motion from framer-motion
import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import galleryImages from "../../constants/gallery-images";

const Gallery = () => {
  const img = galleryImages;

  const sectionStyle = {
    backgroundImage: "inherit",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    height: "100%",
    width: "100%",
    paddingLeft: "10%",
    paddingRight: "10%",
  };

  return (
    <motion.section // Wrap section in motion.section for animations
      style={sectionStyle}
      className="room-slider pt-20"
      id="gallery"
      initial={{ opacity: 0, x: -20 }} // Initial animation state
      animate={{ opacity: 1, x: 0 }} // Animation to apply
      transition={{ delay: 0.3 }} // Delay animation
    >
      <div className="container-fluid p-0">
        <ImageGallery items={img} />
      </div>
    </motion.section>
  );
};

export default Gallery;
