import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ApartmentCard = ({ id, persons, imageUrl }) => {
  const { t } = useTranslation();

  return (
    <Link to={`/apartments/${id}`} replace>
      <div
        className="max-w-sm overflow-hidden shadow-lg bg-white"
        style={{ cursor: "pointer" }}
      >
        <img className="w-full" src={imageUrl} alt="Apartment" />
        <div className="px-6 py-4">
          <h3 className=" text-2xl text-black mb-2">
            {t(`apartment${id}.title`)}
          </h3>
          <span className="inline-block bg-golden-50 rounded-full px-3 py-1 text-sm text-black mr-2 mb-2">
            {persons} {t("persons")}
          </span>
        </div>
        <div className="text-center pb-4">
          <div className="main-btn btn-filled" smooth={true}>
            {t("seeMore")}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default withTranslation()(ApartmentCard);
